
import { appState } from "../AppState";
import Enum from "./enum";
import React from 'react'
import { ConsoleView } from "react-device-detect";
const FeatureToggle = props => {
  const [stateApp, stateAppActions] = appState();

  
  const isConnectriaOrg = !stateApp.resourceProperties.parent;
 

  const criteriaMet = (env,cloud, integrations, policies) =>{


    return(matchedEnv(stateApp,env, props)&& matchedClouds(stateApp, cloud) && matchedIntegrations(stateApp, integrations) && hasPolicies(policies,stateApp));
  }
  const title = React.createElement('null', {}, '');
  let access = props.showAccessDenied || false;
  var Null = <></>;
  let result = criteriaMet(props.envs, props.clouds, props.integrations, props.policies);
 
  let passed = criteriaMet(props.envs, props.clouds, props.integrations, props.policies) && isConnectriaOrg ? props.children : null
  let elm = <NoAccess></NoAccess>;
 
  //const title = null;
// if(passed == null ) console.log(props.children)
return passed;
  //return (access && !passed) ? title : passed
};

const NoAccess = props =>{
  return (
    <div>
      <h3>You do not currently have access to this functionality.</h3>
    </div>
  )
}

export default FeatureToggle;

export const matchedEnv = (stateApp,envs) => {
  try{
    if (typeof envs == "undefined") return false;
    if ((envs.length > 0) && (envs[0] == Enum.Env.ALL)) return true;

    return envs.includes((stateApp.env || Enum.Env.DEV));
  }
  catch{

      return false;
  }
};

export function hasPolicies(policies = [], stateApp){
 let hasPolicy = false
  if(policies && !Array.isArray(policies)){
    policies = [policies]
  }
  let user_roles = stateApp.roles_v2;
  let policy_codes = stateApp.policies;
 // console.log(user_roles)
  if(policies.length == 0) return true;


  if (stateApp.superAdmin) return true;

  if (user_roles.filter((e) => e.RoleName == "OrgAdmin").length > 0) return true;



  for (var i = 0; i < policies.length; i++) {
    if (policy_codes.includes(policies[i])) {
     
      hasPolicy = true
    }
  }
  return hasPolicy;
}

export const matchedIntegrations = (state, integrations) => {
  try{
    if (typeof integrations == "undefined") return true;
    for(var i = 0; i < (integrations || [] ).length; i++){
      if(state.integrations.includes(integrations[i])) return true;
    }
    return false;
  }
  catch{
      return false;
  }
}

export function matchedClouds(state, clouds) {
  for(var i = 0; i < (clouds || [] ).length; i++){
    if(clouds[i] == Enum.CloudCategory.ALL) return true;
    if(state.clouds.includes(clouds[i])) return true;
  }
  return false;
}
function flatten(ary, prop) {
  var ret = [];
  for (var i = 0; i < ary.length; i++) {
    if (Array.isArray(ary[i][prop])) {
      ret = ret.concat(flatten(ary[i][prop], prop));
    } else {
      ret.push(ary[i]);
    }
  }
  return ret;
}

