import moment from "moment";

export const ticketPriorities = 
  [{
      id: 1,
      name: "Emergency"
  },
  {
      id: 2,
      name: "High"
  },
  {
      id: 3,
      name: "Normal"
  },
  {
      id: 4,
      name: "Low"
  }];

export const getTicketPriority = (ticketPriorityId) => {
  return ticketPriorities.find(priority => priority.id == ticketPriorityId).name
}

export const getTicketPriorityColor = (ticketPriorityId) => {
  const ticketPriorityColors = {
    1: "#E84A50",
    2: "#E84A50",
    3: "#FEBE10",
    4: "#8798AD"
  }
  return ticketPriorityColors[ticketPriorityId]
}

export const ticketStatuses = [
  "New",
  "Open",
  "WaitOnCust",
  "Scheduled",
  "Resolved",
  "Closed",
  "Suspended", 
  "Cancelled"
]

export const ticketStatusDisplayMapping = {
  New: "New",
  Open: "Being worked on",
  WaitOnCust: "Awaiting your input",
  Scheduled: "Scheduled",
  Resolved: "Resolved",
  Closed: "Closed",
  Suspended:  "Suspended",
  Cancelled: "Cancelled",
}

 // Being worked on - fa fa-circle
 export const ticketStatusIconMapping = {
  New: "fa fa-circle",
  Open: "fa fa-circle",
  WaitOnCust: "fa fa-circle",
  Scheduled: "fa fa-circle",
  Resolved: "fa fa-check",
  Closed: "fa fa-circle",
  Suspended:  "fa fa-circle",
  Cancelled: "fa fa-circle",
}

// Being worked on - #2E5BFF
export const ticketStatusColorMapping = {
  New: "#2E5BFF",
  Open: "#2E5BFF",
  WaitOnCust: "#D63649",
  Scheduled: "#F0E65B",
  Resolved: "#2E5BFF",
  Closed: "#2E384D",
  Suspended:  "#2E384D",
  Cancelled: "#2E384D",
}

export const getTicketStatusDisplayText = (ticketStatus) => {
  return ticketStatusDisplayMapping[ticketStatus]
}

export const getTicketStatusIcon = (ticketStatus) => {
  return ticketStatusIconMapping[ticketStatus]
}

export const getTicketStatusColor = (ticketStatus) => {
  return ticketStatusColorMapping[ticketStatus]
}

export const formatTicketDate = (datetime) => {
  // 2013-07-18T12:38:02.000-05:00
  let formattedDate = moment(datetime, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("h:mm A MM/DD/YYYY");
  return formattedDate
}