import axios from "axios";
import Cognito from "../utility/CognitoAuth";
import CookieService from "./CookieService";
const FormData = require('form-data');
const base_api =
  "https://4jsb2f47s8.execute-api.us-east-1.amazonaws.com/dev/api";

async function defaultRequest() {
  const idToken = await Cognito.getIdToken();
  let req = axios.create({
    baseURL: base_api,
    headers: { Authorization: idToken },
  });

  return req;
}

const YetiTicketProvider = {
  async getTickets(sort = 'PORTAL') {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    return req.get(base_api + `/${orgId}/tickets/ticketlist?sort=${sort}&cache=1`);
  },
  async getAttachment(attachment_id) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    return req.get(base_api + `/${orgId}/tickets/ticket?attachment=${attachment_id}`,{headers: {Accept: 'application/octet-stream'}});
  },
  async getTicket(ticket_id) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    return req.get(base_api + `/${orgId}/tickets/ticket?ticket_id=${ticket_id}&cache=1`);
  },
  async createTicket(orgId, requesterId, shortDescription, service_id, description, priority) {
    const req = await defaultRequest();
    const data = {
      requester_id: requesterId,
      short_description: shortDescription,
      service_id: service_id,
      description: description,
      internal_use: false,
      origination_code: 'CUSTOMER_PORTAL',
      priority: priority
    };
  
    return req.post(`${base_api}/${orgId}/tickets/ticket`, data);
  },
  async createRecipient(orgId, ticket_id, email) {
    const req = await defaultRequest();
    const data = {
      ticket_id: ticket_id,
      email: email
    };
  
    return req.post(`${base_api}/${orgId}/tickets/recipient`, data);
  },
  async getTicketComments(ticket_id) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    return req.get(base_api + `/${orgId}/tickets/commentlist?ticket_id=${ticket_id}&cache=1`);
  },
  async getTicketRecipientList(ticket_id) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    return req.get(base_api + `/${orgId}/tickets/recipientlist?ticket_id=${ticket_id}&cache=1`);
  },
  async createTicketComment(ticket_id,contact_id, message) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    return req.post(base_api + `/${orgId}/tickets/comment`,{ticket_id: ticket_id, contact_id, message: message});
  },
  async getTicketAttachments(ticket_id) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    return req.get(base_api + `/${orgId}/tickets/attachmentlist?ticket_id=${ticket_id}&cache=1`);
  },
  async getTicketRecipients(ticket_id) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    return req.get(base_api + `/${orgId}/tickets/recipientlist?ticket_id=${ticket_id}&cache=1`);
  },
  async getDocuments(context, context_id) {
    const req = await defaultRequest();
    let request = req.get(
      base_api +
      `/documents/documentlist?context=${context}&context_id=${context_id}`
    );
    return request;
  },
  async download_document(doc_id) {
    const req = await defaultRequest();

    let request = req.get(
      base_api + `/documents/document?document_id=${doc_id}`,
      { Accept: "application/octet-stream" }
    );
    return request;
  },
  parseTicket(ticket) {
    return ({
      id: ticket.id,
      created: ticket.created,
      priority: ticket.priority,
      subject: ticket.short_description,
      issue: ticket.description || '',
      errorDesc: '',
      user_id: ticket.requester_id,
      assigned_to: ticket.handler_id || '',
      status: ticket.status,
      lastUpdate: ticket.updated,
      timeSpentMins: 0,
      ticketcategory_id: ticket.category,
      InitialContact: false,
      device: ticket.service || '',
      closed_on: ticket.closed || ticket.created,
      customer_id: ticket.customer_id,
      enteredBy: ticket.requester_id,
      ticket_update_status_id: ticket.customer_facing_status,
      order_id: ticket.order_id || null,
      nbTimeSpentMins: 0,
      parent_ticket_id: null,
      salesreviewed: false,
      salesreviewedby_id: null,
      salesreviewed_on: null,
      customer_application_name: null,
      team_id: null,
      first_assigned_on: null,
      last_conn_update_on: ticket.created,
      next_conn_update_on: ticket.updated,
      last_cust_update_on: ticket.updated,
      resolved_by_id: ticket.handler_id,
      resolved_on: ticket.resolved,
      closed_by_id: ticket.closed ? ticket.handler_id : null,
      ticket_type_id: ticket.type,
      cancelled_by_id: null,
      cancelled_on: null,
      last_woc_date: null,
      planned_start_date: null,
      planned_end_date: null,
      customer_segment: "Not Assigned",
      user: {
        id: ticket.requester_id,
        login: `${ticket.requester_name?.replace(' ', '').toLowerCase()}`,
        email: `${ticket.requester_name?.replace(' ', '').toLowerCase()}`,
        fname: ticket.requester_name?.split(' ')[0],
        surname: ticket.requester_name?.split(' ')[1] || '',
        role_id: 1,
        customer_id: ticket.customer_id,
        phone: '',
        fax: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        primaryUser: false,
        passresetphrase: null,
        lastpagehit: null,
        lastpagerequest: null,
        lastlogin: null,
        monitorAccess: false,
        homePhone: null,
        cellPhone: null,
        comments: '',
        browserAgent: null,
        active: true,
        lastip: null,
        updated_on: new Date().toISOString(),
        created_on: ticket.created,
        title: '',
        office_hours_start: null,
        office_hours_end: null,
        manager_user_id: null,
        primary_team_id: null,
        serviceUser: false,
        cognito_user_guid: '',
        login_supported: true,
        le_customer_id: null,
        le_contact_id: null
      }
    });
  },
  // TODO: implement this function
  parseTicketAttachment(original, ticket_id) {
    return  {
        id: original.id,
        created: original.created,
        filename: original.filename,
        odin_path: original.author,  // Assuming `author` URL can be used similarly to `odin_path`
        odin_uploaded: true,  // Assuming the file is successfully uploaded
        path: '',  // You might want to generate this dynamically based on some logic
        ticket_id: ticket_id,  // Same as above
        author_id: original.author_id,
        author_name: original.author_name,
        comment_id: original.comment_id,
        mimetype: original.mimetype,
        name: original.name,
        type: original.type,
        updated: original.updated
    };
  },
  // TODO: implement this function
  parseTicketRecepient(recipient) {
    return {}
  },
  // TODO: implement this function
  parseTicketDocument(doc) {
    return {}
  }
};

export default YetiTicketProvider;
