const CONNECTRIA_DOMAIN = 'tria.connectria.com',
  hostname = window.location.hostname;

let ACCOUNTS_API = 'https://accounts-dev.' + CONNECTRIA_DOMAIN,
  ACCOUNTS_URL = 'https://accounts.dev.' + CONNECTRIA_DOMAIN,
  ENV = 'dev',
  DOMAIN = 'dev.' + CONNECTRIA_DOMAIN,
  AWS_IDENTITY_POOL_ID = 'us-east-1:2e6d0ea2-1e08-476e-a6df-c4ee1981821b',
  AWS_USER_POOL_ID = 'us-east-1_MVORKBzco',
  AWS_POOL_CLIENT_ID = '73ce0eb09mvj20o2ir78jn51sh';

if (hostname.indexOf('labs.demo.') > -1) {
  ENV = 'qa';
  ACCOUNTS_API = 'https://accounts-demo.' + CONNECTRIA_DOMAIN;
  ACCOUNTS_URL = 'https://accounts.demo.' + CONNECTRIA_DOMAIN;
  AWS_IDENTITY_POOL_ID = 'us-east-1:38abc155-0555-4f34-92dd-7d76b0aae10b';
  AWS_USER_POOL_ID = 'us-east-1_vlVCSH4IH';
  AWS_POOL_CLIENT_ID = '213k84a2ftsbhda9i2v7eo9rpp';
}
if (hostname.indexOf('qa.tria') > -1) {
  ENV = 'qa';
  ACCOUNTS_API = 'https://accounts-qa.' + CONNECTRIA_DOMAIN;
  ACCOUNTS_URL = 'https://accounts.qa.' + CONNECTRIA_DOMAIN;
}
if (hostname.indexOf('tria-labs.connectria') > -1) {
  ENV = 'prod';
  ACCOUNTS_API = 'https://accounts-api.' + CONNECTRIA_DOMAIN;
  ACCOUNTS_URL = 'https://accounts.' + CONNECTRIA_DOMAIN;

  AWS_IDENTITY_POOL_ID = 'us-east-1:f384b212-4e86-4009-a6ff-850feaf47b7c';
  AWS_USER_POOL_ID = 'us-east-1_pccC7Xpyj';
  AWS_POOL_CLIENT_ID = '39v8p4nn1166aef6s4v1jtcoku';
}
if (hostname.indexOf('next-gen.tria') > -1) {
  ENV = 'prod';
  DOMAIN = CONNECTRIA_DOMAIN;
  ACCOUNTS_API = 'https://accounts-api.' + CONNECTRIA_DOMAIN;
  ACCOUNTS_URL = 'https://accounts.' + CONNECTRIA_DOMAIN;

  AWS_IDENTITY_POOL_ID = 'us-east-1:f384b212-4e86-4009-a6ff-850feaf47b7c';
  AWS_USER_POOL_ID = 'us-east-1_pccC7Xpyj';
  AWS_POOL_CLIENT_ID = '39v8p4nn1166aef6s4v1jtcoku';
}
if (hostname.indexOf('labs-prod.tria') > -1) {
  ENV = 'prod';
  DOMAIN = CONNECTRIA_DOMAIN;
  ACCOUNTS_API = 'https://accounts-api.' + CONNECTRIA_DOMAIN;
  ACCOUNTS_URL = 'https://accounts.' + CONNECTRIA_DOMAIN;

  AWS_IDENTITY_POOL_ID = 'us-east-1:f384b212-4e86-4009-a6ff-850feaf47b7c';
  AWS_USER_POOL_ID = 'us-east-1_pccC7Xpyj';
  AWS_POOL_CLIENT_ID = '39v8p4nn1166aef6s4v1jtcoku';
}
if (hostname.indexOf('localhost') > -1) {
  DOMAIN = 'localhost';
  ACCOUNTS_API = 'http://localhost:3000';
}

console.log('Domain', DOMAIN);

export { ACCOUNTS_API, ACCOUNTS_URL, DOMAIN, ENV, AWS_IDENTITY_POOL_ID, AWS_USER_POOL_ID, AWS_POOL_CLIENT_ID };
