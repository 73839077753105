import React from 'react';
import moment from 'moment-timezone';
import { Badge } from 'reactstrap';
import { appState } from '../../AppState';
function LightEdgeHistoryItem({ comment }) {
  const momentTzGuess = moment.tz.guess();
  const created = moment(comment.created).tz(momentTzGuess).format('LLL z');
  const isConnectriaUser = comment.author_id.startsWith('ACT-');
  const [stateApp, stateAppActions] = appState();
  return (
    <div className={`mt-2 ${isConnectriaUser ? 'text-left' : 'text-right'}`}>
      <div className="p-2 row" style={{ backgroundColor: '#e9e9e9' }}>
        <div className="col-10">
          <p className="mb-0">
            Comment by:{' '}<span style={{ textTransform: 'capitalize', fontWeight: 700 }}>{comment.author_name}</span> 
            
          </p>
          <p className="mb-0">{created}</p>
        </div>
        <div className="col-2 d-flex align-items-center justify-content-end">
       <></>
        </div>
      </div>
      {comment.has_attachment === "TRUE" && (
        <Badge variant="info" className="mt-1">
          Has Attachment
        </Badge>
      )}
      <p className="pb-3 pt-1" style={{ fontSize: '0.97rem', whiteSpace: 'pre-wrap' }}>
        {comment.message}
      </p>
    </div>
  );
}

export default LightEdgeHistoryItem;